import { dashboard } from "@/api/dashboard.js";
import { booking } from "@/api/booking.js";
import dayjs from "dayjs";
export default {
  components: {},
  data: () => ({
    dataDashboard: "",
    openDialogCancel: false,
    cancelBookingItem: null,
  }),
  created() {
    this.getDashboardMember();
  },
  methods: {
    async getDashboardMember() {
      await dashboard.getDashboardMember().then((res) => {
        this.dataDashboard = res;
      });
    },
    editBooking(id) {
      return this.$router.push({
        name: "editBooking",
        params: {
          id: id,
        },
      });
    },
    async confirmCancelBooking() {
      let body = {
        id: this.cancelBookingItem.ID,
      };
      await booking.cancelBooking(body).then((res) => {
        if (res.code == "SUCCESS") {
          this.openDialogCancel = false;
          this.getDashboardMember();
          this.$vs.notification({
            color: "success",
            position: "top-right",
            duration: "2000",
            title: "Huỷ đặt ghế thành công !",
          });
        }
      });
    },
    _dayjs: dayjs,
  },
};
